import Content, { HTMLContent } from '../components/Content/Content'
import React, { FC } from 'react'
import { SectionTheme, Theme } from '../types'

import AdamDesk from '../img/AdamDesk'
import { Alignment } from '../types/Enums'
import GridLines from '../components/GraphPaper/GraphPaper'
import Header from '../modules/Header/Header'
import { LargePrimaryButton } from '../components/Button/Button'
import Link from '../components/Link/Link'
import SketchyDiv from '../components/SketchyDiv/SketchyDiv'
import styled from '@emotion/styled'

type SectionType = {
  color?: string
  sectionTheme?: SectionTheme
  theme: Theme
  bgColor?: string
  hasImgLeft?: boolean
  hasImgRight?: boolean
}

const Container = styled.section<SectionType>`
  position: relative;
  display: grid;
  background-color: ${({ sectionTheme, bgColor, theme }) =>
    (bgColor !== '' && bgColor) ||
    (sectionTheme && theme[sectionTheme]?.bgColor) ||
    'transparent'};
  color: ${({ color, theme, sectionTheme }) =>
    (color !== '' && color) ||
    (sectionTheme && theme[sectionTheme]?.color) ||
    'rgba(0, 0, 0, 0.8)'};
  grid-template-columns: [col1Start] 0 [col1End col2Start col3Start] 1fr [col2End col3End col4Start] 0 [col4End];
  grid-gap: 1.5em;
  height: 100%;
  width: 100%;
  padding: 3.5em 0;
  h1,
  h2,
  h3,
  h4 {
    /* margin: 0; */
    text-align: center;
  }
  p {
    color: ${({ color, theme, sectionTheme }) =>
      (color !== '' && color) ||
      (sectionTheme && theme[sectionTheme]?.color) ||
      'rgba(0, 0, 0, 0.75)'};
  }

  @media (min-width: 1200px) {
    display: grid;
    grid-gap: 5em;
    padding: 2em 0;
    grid-template-columns: ${({ hasImgLeft, hasImgRight }) =>
      `[col1Start] ${
        hasImgLeft ? '2%' : '6%'
      } [col1End col2Start] 1fr [col2End col3Start] 1fr [col3End col4Start] ${
        hasImgRight ? '2%' : '6%'
      } [col4End]`};
    h1,
    h2,
    h3,
    h4 {
      text-align: left;
    }
  }
`

const FullPageContainer = styled(Container)`
  @media (min-width: 1200px) {
    min-height: calc(100vh - 4em);
  }
`

const ContentContainer = styled.div<{ align?: Alignment }>`
  position: relative;
  grid-column: ${({ align }) => {
    if (align === Alignment.LEFT) {
      return `col2Start / col2End`
    }
    if (align === Alignment.RIGHT) {
      return `col3Start / col3End`
    }
    return `col2Start / col3End`
  }};
  display: grid;
  align-self: center;
  grid-row-gap: 2em;
  grid-row-gap: clamp(2em, 1.5vw, 4em);

  @media (min-width: 400px) {
    text-align: justify;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 1200px) {
    justify-content: unset;
  }
`
const StyledHeader = styled(Header)`
  justify-content: center;
  @media (min-width: 1200px) {
    justify-content: unset;
  }
`

const PaddedContainer = styled.div`
  padding: 10px;
  display: grid;
  grid-gap: 2em;
  grid-row-gap: clamp(2em, 1.75vw, 4em);
`
const H1 = styled.h1<{ theme: Theme }>`
  color: ${({ theme }) => theme.color.primary};
`
const BackgroundContainer = styled.div<{ theme: Theme }>`
  position: absolute;
  top: 0;
  left: 0%;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.secondary};
`

const GraphPaper = styled(GridLines)<{ theme: Theme }>`
  background-color: ${({ theme }) => theme.color.background};
  @media (min-width: 1200px) {
    clip-path: ellipse(68% 70% at 0% 50%);
  }
`
const ImageContentContainer = styled(ContentContainer)<{ theme: Theme }>`
  @media (max-width: 1199px) {
    background-color: ${({ theme }) => theme.color.secondary};
    padding: 2em;
    box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    justify-self: center;
    width: 100%;
    max-width: 65%;
  }
`

const ServicesContainer = styled.div`
  display: grid;
  grid-row-gap: 2em;
  grid-column-gap: 3em;
  justify-items: center;

  @media (min-width: 1600px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`

const MaxWidthArticle = styled.article`
  max-width: 70ch;
  h1,
  h2,
  h3 {
    max-width: 40ch;
  }
`

const ServiceContent = styled.div<{
  color?: string
  bgColor: string
  theme: Theme
}>`
  border-radius: 32px;
  padding: 1em;
  background-color: ${({ bgColor }) => bgColor};
  box-shadow: ${({ theme }) => theme.boxShadow};
  color: ${({ color }) => color};
  p {
    color: ${({ color }) => color};
  }
  @media (min-width: 992px) {
    padding: 2em;
  }
`

const SketchyHomepageDiv = styled(SketchyDiv)<{ theme: Theme }>`
  font-family: ${({ theme }) => theme.titleFont};
`

export type IndexPageTemplateProps = {
  name: GatsbyTypes.MarkdownRemarkFrontmatter['name']
  role: GatsbyTypes.MarkdownRemarkFrontmatter['role']
  html: GatsbyTypes.MarkdownRemark['html']
  contentComponent: FC
  navItems: GatsbyTypes.MarkdownRemarkFrontmatterNavItems[]
  sections: GatsbyTypes.MarkdownRemarkFieldsSections[]
  services: GatsbyTypes.MarkdownRemarkFieldsServices
}

export const IndexPageTemplate: FC<IndexPageTemplateProps> = ({
  name,
  role,
  html,
  contentComponent: HTMLFromMDBody = Content, // this is only for the body widget
  navItems,
  sections = [],
  services,
}) => {
  const { serviceTitle, servicesList = [] } = services
  return (
    <>
      <FullPageContainer hasImgRight>
        <BackgroundContainer>
          <GraphPaper />
        </BackgroundContainer>
        <ContentContainer align={Alignment.LEFT}>
          <Flex>
            <SketchyDiv>
              <H1>{name}</H1>
            </SketchyDiv>
          </Flex>
          <Flex>
            <SketchyDiv>
              <h2 style={{ margin: '0.1em 0' }}>{role}</h2>
            </SketchyDiv>
          </Flex>
          <PaddedContainer>
            <StyledHeader links={navItems} />
            <Flex>
              <SketchyHomepageDiv>
                <HTMLFromMDBody>{html}</HTMLFromMDBody>
              </SketchyHomepageDiv>
            </Flex>
            <Flex>
              <LargePrimaryButton as={Link} to="/contact">
                Contact Me
              </LargePrimaryButton>
            </Flex>
          </PaddedContainer>
        </ContentContainer>
        <ImageContentContainer align={Alignment.RIGHT}>
          <AdamDesk />
        </ImageContentContainer>
      </FullPageContainer>
      <FullPageContainer>
        <ContentContainer align={Alignment.CENTER}>
          <h2 style={{ textAlign: 'center' }}>{serviceTitle}</h2>
          <ServicesContainer>
            {servicesList.map(({ title, content, icon, bgColor, color }) => {
              return (
                <ServiceContent bgColor={bgColor} color={color} key={title}>
                  <MaxWidthArticle>
                    {icon && <i className={icon} />}
                    <h3>{title}</h3>
                    <HTMLContent>{content}</HTMLContent>
                  </MaxWidthArticle>
                </ServiceContent>
              )
            })}
          </ServicesContainer>
        </ContentContainer>
      </FullPageContainer>
      {/* todo: add a theme parameter to allow for fast theming */}
      {sections.map(({ title, body, align, bgColor, color, theme }) => (
        <FullPageContainer
          key={title}
          sectionTheme={theme as SectionTheme}
          bgColor={bgColor}
          color={color}
        >
          <ContentContainer align={align as Alignment}>
            <h2>{title}</h2>
            <MaxWidthArticle>
              <HTMLContent>{body}</HTMLContent>
            </MaxWidthArticle>
          </ContentContainer>
        </FullPageContainer>
      ))}
    </>
  )
}
